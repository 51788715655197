.home-header-button {
    width: 70%;
    max-width: 750px;
    height: 50px;
    text-align: center;
    padding-top: 4%;
    margin: 0 auto;
    margin-bottom: 12px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 4px !important;
    box-shadow: 1px 2px 12px 1px lightgrey;
    background-color: transparant; 
}

.home-header-button:hover {
    background-color: #0860e4;
    color: white;
}

.router-link {
    text-decoration: none;
    color: inherit;
}

.site-title {
    text-align: left;
    margin: 0 auto;
    margin-left: 15px;
    top: 10px;
    font-size: 25px;
    position: relative;
    color: #0860e4;
}

.site-line {
    width: 100%;
    height: 1px;
    margin: 0 auto;
    margin-top: 18px;
    background-color: lightgray;
}

.shiller-info {
    width: 90%;
    padding: 5px;
    background-color: lightgray;
    border-radius: 3px;
    position: absolute;
    margin: 0 auto;
    right: 0;
    left: 0;
    bottom: 5px;
    font-size: 14px;
    text-align: center;
    color: #606060;
    cursor: pointer;
}

.application-container {
    width: 80%;
    max-width: 800px;
    position: absolute;
    top: 20%;
    right: 0;
    left: 0;
    margin: 0 auto;
    border-radius: 5px !important;
    box-shadow: 1px 2px 12px 1px #6363637a;
    background-color: transparant; 
}

.application-p {
    margin-left: 5%;
    margin-bottom: -12px;
    font-weight: 300;
}

.input-container {
    width: 90%;
    height: 40px;
    background-color: #ececec7d;
    margin: 0 auto;
    margin-top: 15px;
    border-radius: 10px;
}

.input-i {
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: none;
}

.approve-button {
    background-color: #ddebff;
    color: #0860e4;
    width: 100px;
    font-weight: bold;
    padding: 3px;
    border-radius: 15px;
    text-align: center;
    margin-top: 5px;
    margin-left: 5%;
    cursor: pointer;
}

.overview-template {
    width: 90%;
    height: 35px;
    margin: 0 auto;
    margin-top: 5px;
    position: relative;
}

.apply-button {
    position: relative;
    padding: 14px;
    background-color: #0860e4;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    bottom: 10px;
    margin: 0 auto;
    margin-top: 20px;
    width: 265px;
    left: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
}

.info-icon {
    width: 35px;
    height: 35px;
    /* background-color: lightblue; */
    border-radius: 50%;
    display: inline-block;
    position: relative;
}

.overview-text {
    display: inline-block;
    position: absolute;
    top: 6px;
    left: 45px;
    font-weight: 500;
    color: #0860e4;
    cursor: pointer;
}

.back-button {
    width: 60px;
    height: 30px;
    margin-left: 15px;
    margin-top: 10px;
    position: relative;
}

.b-arrow-img {
    height: 100%;
}

.apps-container {
    width: 300px;
    height: 35px;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 20px;
    border: lightgray 1px solid;
    position: relative;
}

.approved-apps {
    display: inline-block;
    border-right: solid 1px lightgray;
    width: 49%;
    height: 36px;
    text-align: center;
}

.pending-apps {
    display: inline-block;
    border-left: solid 1px lightgray;
    width: 49%;
    height: 36px;
    text-align: center;
}

.no-props {
    text-align: center;
    color: lightgray;
}

.profit-title {
    text-align: center;
    margin-top: 10px;
    color: #838383;
}

.profit-rewards {
    width: 65%;
    max-width: 750px;
    height: 130px;
    background-color: #2462be;
    margin: 0 auto;
    margin-top: 12px;
    border-radius: 12px;
    position: relative;
}

.p-r-title {
    color: white;
    font-size: 18px;
    position: absolute;
    top: 5px;
    left: 10px;
    font-weight: bold;
}

.p-r-eth {
    color: white;
    font-size: 35px;
    position: absolute;
    top: 30px;
    right: 18px;
    font-weight: bold;
    width: auto;
}

.p-r-claim {
    background-color: #ddebff;
    color: #0860e4;
    width: 100px;
    font-weight: bold;
    padding: 3px;
    border-radius: 15px;
    text-align: center;
    margin-top: 5px;
    margin-left: 5%;
    cursor: pointer;
    position: absolute;
    right: 15px;
    bottom: 5px;
}

.eth-locked {
    width: 70%;
    height: 115px;
    margin: 0 auto;
    margin-bottom: -10px;
    margin-top: 15px;
    background-color: #0860e4;
    color: white;
    border-radius: 8px;
    position: relative;
}

.e-locked-text {
    font-size: 18px;
    font-weight: 600;
    position: relative;
    left: 10px;
    top: 15px;
}

.eth-add {
    background-color: #ddebff;
    color: #0860e4;
    width: 100px;
    font-weight: bold;
    padding: 6px;
    border-radius: 15px;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-top: 23px;
    cursor: pointer;

}

.disconnect {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: lightgreen;
    border-radius: 50%;
    left: 4px;
    top: 2px;
    position: relative;
}

.shiller-apply-container {
    width: 100%;
    height: 100%;
    background-color: #0000008a;
    /* opacity: .4; */
    position: fixed;
    top: 0;
    left: 0;
}

.shiller-apply {
    width: 80%;
    max-width: 750px;
    height: 60%;
    background-color: white;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -80%;
    opacity: 0;
    animation-name: slide-up;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

@keyframes slide-up {
    0% {
        opacity: 0;
        bottom: -80%;
    }
    100% {
        opacity: 1;
        bottom: 0;
    }
}

.link-text {
    height: 160%;
    margin-top: -30px;
    top: 0;
    padding-top: 30px;
}